import script from "./Date.vue?vue&type=script&setup=true&lang=js"
export * from "./Date.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QDate from 'quasar/src/components/date/QDate.js';
import QTime from 'quasar/src/components/time/QTime.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDate,QTime});
