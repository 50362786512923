<template>
  <p class="q-mt-md">{{ label }}:</p>
  <div class="q-gutter-md row items-start">
    <q-date
      :label="label || undefined"
      v-model="date"
      :mask="dateTime ? 'YYYY-MM-DDTHH:mm:ss.000000' : 'YYYY-MM-DD'"
    />
    <q-time
      v-if="dateTime"
      :label="label || undefined"
      v-model="date"
      mask="YYYY-MM-DDTHH:mm:ss.000000"
    />
  </div>
</template>

<script setup>
import { computed, defineEmits } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  label: String,
  value: String,
  dateTime: Boolean,
});

const emits = defineEmits(["updateRowRef"]);

const date = computed({
  get() {
    return props.value;
  },
  set(val) {
    emits("updateRowRef", props.dateTime ? val + "Z" : val);
  },
});
</script>
